import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

const Link = styled.a`
  width: auto;
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  font-weight: bold;
  border: 3px solid white;
  color: black;
  padding: 0.5em 1em;
  transition: all 250ms linear;
  pointer-events: all;
  text-decoration: none;
  font-size: 1em;
  text-transform: uppercase;
  top: 14px;
  /* transform: translateY(10px); */

  &:hover {
    border: 3px solid white;
    background: black;
    color: white;
    /* transform: translateY(5px); */
  }
  ${({ isScrolling }) =>
    isScrolling &&
    css`
      position: absolute;
      right: 0;
    `};
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`

const GetStarted = ({ isScrolling }) => {
  const { t } = useTranslation()

  return (
    <Link
      isScrolling={isScrolling}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={t('getStarted')}
      href="https://seb432889.typeform.com/to/O8jf2l"
    >
      {t('getStarted')}
    </Link>
  )
}

export default GetStarted
